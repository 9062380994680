<template lang="pug">
.checkbox-group
  .option(v-for="item of options" :key="item[keyField]")
    input(
      v-model="value"
      type="checkbox"
      :disabled="disabledOptions.includes(item.inputId ? item.inputId : item[keyField])"
      :id="item.inputId ? item.inputId : `checkbox__${ id }_${ item[keyField] }`"
      :value="fullInfo ? item : item[keyField]"
    )
    label(:for="item.inputId ? item.inputId : `checkbox__${ id }_${ item[keyField] }`")
      slot(name="label" :option="item") {{ item[field] }}
  slot
</template>

<script lang="ts">
import type { PropType } from 'vue';
import type { BaseObjectI } from "@/stores/manuals/ManualsInterface";
import { defineComponent } from 'vue';
import { useVModel } from "@vueuse/core";

interface CheckboxI extends BaseObjectI {
  inputId?: string,
}

export default defineComponent({
  name: "CheckboxGroup",
  emits: [
    'update:modelValue',
  ],
  props: {
    modelValue: {},
    options: {
      type: Array as PropType<CheckboxI[]>,
      default: () => [],
    },
    field: {
      type: String,
      default: 'title',
    },
    keyField: {
      type: String,
      default: 'id',
    },
    fullInfo: {
      type: Boolean,
      default: false,
    },
    disabledOptions: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    id: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {

    const value = useVModel(props, 'modelValue', emit)

    return {
      value,
    }
  }
})
</script>

<style scoped lang="scss">
.option {
  font-size: 14px;
  box-sizing: content-box;
  min-height: 32px;
  display: flex;
  //align-items: center;
  width: 100%;

  label {
    margin: 8px 0 !important;
    width: 100%;
  }
}
</style>
